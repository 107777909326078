<template>
  <div v-if="tagsPageState">
    <div>
      <MonthYearSelector
        @monthYearSelected="monthYearSelected"
        :isLoadingLeft="isLoadingLeft"
        :isLoadingRight="isLoadingRight"
        :showMonthSelector="false"
        :month="0"
        :year="tagsPageState.year"
      />
    </div>

    <div class="select is-small">
      <select v-model="tagsPageState.nrOfTags" @change="getTagRank">
        <option
          v-for="amount in tagsPageState.amountChoices"
          :key="amount"
          :value="amount"
          :selected="amount === tagsPageState.nrOfTags"
          >{{ amount }}</option
        >
      </select>
    </div>

    <hr />

    <div
      v-if="loadingDone && tagsPageState.tagUsageItems.length > 0"
      class="columns columns-tagStats is-multiline"
    >
      <div
        v-for="month in months"
        :key="month"
        class="column is-half is-one-third-desktop is-one-quarter-widescreen"
      >
        <p class="title is-4">{{ month | getMonthName }}</p>
        <table class="table is-striped is-narrow">
          <tbody>
            <tr v-for="tag in tagUsageFiltered(month)" :key="tag.Id">
              <td>{{ tag.Rank }}.</td>
              <td width="250">
                <a @click="selectTag(tag)"
                  ><span
                    class="tag"
                    :class="{
                      'is-warning': tagsPageState.selectedTag === tag.TagId,
                      'is-link': tagsPageState.selectedTag !== tag.TagId,
                    }"
                    >{{ tag.Tag }}</span
                  ></a
                >
              </td>
              <td width="50" class="has-text-right">{{ tag.TimesUsed }}</td>
              <td width="50" class="has-text-right">
                <a @click="showTagSummary(tag)" class="is-small">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'info-circle']" />
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="isSearching">
        <ui-loader />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import tagProvider from '@/providers/tag'

const MonthYearSelector = () => import('@/components/UI/MonthYearSelector')

export default {
  components: {
    MonthYearSelector,
  },

  data() {
    return {
      isLoadingLeft: false,
      isLoadingRight: false,
      isSearching: false,
      loadingDone: false,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    }
  },

  computed: {
    ...mapState('tagStore', ['tagsPageState']),
  },

  mounted() {
    this.getTagRank()
  },

  methods: {
    ...mapMutations('tagStore', ['setTagsPageState']),

    monthYearSelected(data) {
      let self = this

      self.tagsPageState.year = data.year
      self.tagsPageState.tagUsageItems = []

      self.isLoadingLeft = data.direction === 'left'
      self.isLoadingRight = data.direction === 'right'
      self.getTagRank()
    },

    getTagRank() {
      let self = this

      self.loadingDone = false
      self.isSearching = true

      tagProvider.methods
        .getTagRank(self.tagsPageState.year, 0, self.tagsPageState.nrOfTags, 0)
        .then((response) => {
          if (response.status === 200) {
            self.tagsPageState.tagUsageItems = response.data
          }
        })
        .finally(() => {
          self.isLoadingLeft = false
          self.isLoadingRight = false

          self.loadingDone = true
          self.isSearching = false
        })

      self.setTagsPageState(self.tagsPageState)
    },

    showTagSummary(tag) {
      let t = {
        Id: tag.TagId,
        Text: tag.Tag,
      }

      this.$emit('showTagSummary', t)
    },

    selectTag(tag) {
      let self = this

      if (self.tagsPageState.selectedTag === tag.TagId) {
        self.tagsPageState.selectedTag = 0
      } else {
        self.tagsPageState.selectedTag = tag.TagId
      }

      self.setTagsPageState(self.tagsPageState)
    },

    tagUsageFiltered(month) {
      let self = this
      let tags = []

      tags = self.tagsPageState.tagUsageItems.filter((t) => t.Month === month)

      return tags
    },
  },
}
</script>
